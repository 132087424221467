.leaflet-tooltip {
    background-color: transparent;
    color: white; /* Set the color of the text to black */
    font-weight: bold;
    border: none;
    box-shadow: none;
}

.leaflet-tooltip::before {
    border: none;
}